
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';


const BackArrow = ({buttonText, handleBack}) => {

    // const handleBack = () => {

    //     alert("Test")

    // }
  return (
    <button onClick={handleBack} style={{ display: 'flex', alignItems: 'center' }} className='mt-3 mb-3'>
    <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '8px' }} />
    {buttonText}
</button>
  )
}

export default BackArrow