
import React, { useEffect, useState } from 'react';
import { useParams , useNavigate} from 'react-router-dom';
import axios from 'axios';
import Mynav from '../../components/Header/Header'
import { Container, Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import SpinnerComponents from '../../components/Spinner/SpinnerComponents'
import PaginationComponent from '../../components/Pagination/Pagination'
import BackArrow from '../../components/BackArrow/BackArrow';
const ShowPage = () => {
    const { id } = useParams(); // Get the ID from the URL
    const [show, setShow] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading , setLoading] = useState(false)
    //let {shows:myshows, loading:isloading} = shows
 let currentItem =[];
 let currentItems
 let totalPages
 const itemsPerPage = 9; // You can ch

    useEffect(() => {
        // Fetch the show details from the API using the ID
        const fetchShowDetails = async () => {
            try {
                const response = await axios.get(`https://nastaanaisie.pythonanywhere.com/api/videos/`);
                console.log(response)
                setShow(response.data);
            } catch (error) {
                console.error('Error fetching the show details:', error);
            }
        };

        fetchShowDetails();
    }, []);

    const navigate = useNavigate()

const goBack = () => {


 navigate('/shows')
}

if(show){


     currentItem = show.filter(item => {

        return item.episode.show == id 

    })

    console.log(currentItem)

  


}

let totalItems = currentItem.length;
totalPages = Math.ceil(totalItems / itemsPerPage);
let indexOfLastItem = currentPage * itemsPerPage;
let indexOfFirstItem = indexOfLastItem - itemsPerPage;
currentItems = currentItem.slice(indexOfFirstItem, indexOfLastItem);

const handlePageChange = (page) => {
    setCurrentPage(page);
  };
   










 
  return (

   <Container>
    {/* <div className='show-cat'>
      {category && category.map(item => (

<p className='cat' onClick={(e) => catFilter(item.name)}>{item.name}</p>
      ))}
  
    </div> */}
     
     <BackArrow buttonText="Back To Shows" handleBack={goBack }/>


        <Row className='mt-3'>
       

          
        {loading? <SpinnerComponents/> : currentItems.map(item => (

        
        <Col sm={4} lg={4} md={4} key={item.id}>
     

        {/* <iframe src={item.Image}  className='featuredImage mb-0' /> */}
        <iframe src={item.video_url} ></iframe>
  
       <h6 className='mt-2'>{item.title}</h6>
        
      
        
        </Col>

        

))}
     


        
      </Row>
     

      <PaginationComponent
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />

    </Container>
  )
};


export default ShowPage