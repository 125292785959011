import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from 'axios'
import { useState, useEffect} from "react";




const initialState = {

    newsdata: [],
    products: []
}






export const getData = () => {

    return async dispatch => {
        try {
            let res = await axios.get('https://nastaanaisie.pythonanywhere.com/api/news/')
            dispatch(getNews(res.data)) //store first five posts
        }
        catch(e){
            console.log(e)
        }
    }
        

}



export const News = createSlice({

    name: 'news',

    initialState: initialState,

    reducers:{

    getNews: (state, action) => {
        //return {...state.newsdata,newsdata: [...state.newsdata, ...payload]}

        state.newsdata.push(action.payload)

       // return state.newsdata.push(payload)
    },


  

     

    // fetchNews: (state,{payload}) => {

    //  state.newsdata.push(payload)
    // // return {newsdata: [...state.newsdata, ...payload]}

   

    // }

 

    
}


})


    


export const {getNews, fetchNews} = News.actions

export default News.reducer