
import axios from 'axios'
import { useEffect } from 'react'

//synchronous action creator


const fetchProgramData= (programs) => ({


    type: 'FETCH_PROGRAM',
    payload: {programs}
})






export const fetchprograms =  () => {
    return async dispatch => {
       //https://mychannel7tv.co.uk/wp-json/wp/v2/schedules
        try {
            let posts = await axios.get('https://nastaanaisie.pythonanywhere.com/api/shows/')
            dispatch(fetchProgramData(posts.data)) //store first five posts
           // dispatch(loading(true))

          
        }
        catch(e){
            console.log(e)
        }
    }
}