import React, { useEffect, useState, useRef } from 'react'
import Mynav from '../../components/Header/Header'
import { Container, Col, Row } from 'react-bootstrap'
import { MemoryRouter, Routes, Route, Link } from 'react-router-dom';
import axios from 'axios'
import moment from 'moment'
import Spinner from 'react-bootstrap/Spinner';

import './schedule.css'

import PaginationComponent from '../../components/Pagination/Pagination';
import SpinnerComponents from '../../components/Spinner/SpinnerComponents';
import { useDispatch, useSelector } from 'react-redux';
import { fetchprograms } from '../../redux/actions/programactions';
import TVSchedule from '../../components/TVSchedule/TVSchedule';
const Schedule = () => {



  const [thumbsSwiper, setThumbsSwiper] = useState(null);



  const[show , setShow] = useState([])

  const [getRandom, setGetrandom] = useState([]);

  const [movies, setMovies] = useState([])





  const swiperRef = useRef(null);

 













  
const [post, setPost] = useState([])


const [loading, setLoading] = useState(false)


const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6; // You can ch



const dispatch = useDispatch()






const format2 = "YYYY-MM-DD"
const regex = /(<([^>]+)>)/gi;
  useEffect(() => {


    //const myfetch =  async() => {

    //const res = await axios.get('https://mychannel7tv.co.uk/wp-json/wp/v2/schedules')

  //console.log("sche",res.data)
  //setPost(res.data)
  
  // setLoading(true)
   
  
  //   }

  //   myfetch()

    dispatch(fetchprograms())

  }, [])

  const programinfo = useSelector(state => state.programs)

  const {loading:isloading, programs} = programinfo

  const totalItems = programs.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = programs.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };




 
  return (
   <Container>




    <TVSchedule/>
       



    
    </Container>
  )


  
}

export default Schedule
