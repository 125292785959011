import React, {useEffect, useState} from 'react'
import axios from 'axios'
import { Col, Row } from 'react-bootstrap'
import Container from 'react-bootstrap/esm/Container';
import { useParams } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import './Post.css'
import BackArrow from '../../components/BackArrow/BackArrow';
const Post = () => {
    let { id } = useParams();


const [news, setNews] = useState()

const navigate = useNavigate()

const goBack = () => {


 navigate('/news')
}
   

    useEffect(() => {
        // Fetch the show details from the API using the ID
        const fetchShowDetails = async () => {
            try {
                const response = await axios.get(`https://nastaanaisie.pythonanywhere.com/api/news/${id}/`);
                console.log("news",response.data)
                setNews(response.data);
            } catch (error) {
                console.error('Error fetching the show details:', error);
            }
        };

       fetchShowDetails();
    }, [id]);



  return (


    <Container>

<BackArrow buttonText="Back To News" handleBack={goBack}/>
      <Row>
        <Col lg={12}>

      

        <div className='text-center mx-auto mr-auto'>


        
        <img src={news && news.Image} className='news-img' />
       
      <h2 className='text-center'>{news && news.title}</h2> 
     
    
      <span className='badge'>{news && new Date(news.publish_date).toDateString()}</span> ||<span className='badge'>{news && news.Author}</span> || <span className='badge'> Article Source: {news && news.newsSource}</span> || <span className='badge'>Image Credit : {news && news.imageCredit}</span>


    


      <p className='news-para'>{news && news.content}</p>
  
      
      </div>
        </Col>


       

<h3 className='text-center text-danger'>Related News</h3>
{news && news.related_news.map(item => (



<Col className='text-center mx-auto mr-auto'>
<p><Link to={`/news/${item.id}`} className='badge badge-danger '>{item.title}</Link></p>

</Col>

))}


      </Row>
     
    

    </Container>
  )
}

export default Post
