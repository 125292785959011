
import axios from 'axios'
import { useEffect } from 'react'

//synchronous action creator


const fetchShowsData= (shows) => ({


    type: 'FETCH_SHOW',
    payload: {shows}
})


const updateShowsData = (shows) => ({

    type: 'UPDATE_SHOW',
    payload: {shows}
})

export const editPost = (shows) => (dispatch) => {
    dispatch(updateShowsData(shows));
  };

export const fetchShows =  () => {
    return async dispatch => {
        try {
            //let posts = await axios.get('https://mychannel7tv.co.uk/wp-json/wp/v2/show')
            let posts  = await axios.get('https://nastaanaisie.pythonanywhere.com/api/shows/')
            dispatch(fetchShowsData(posts.data)) //store first five posts
           // dispatch(loading(true))

           console.log("pos", posts)
          
        }
        catch(e){
            console.log(e)
        }
    }
}

