const initialState = {

   
    loading: false,
    videos: []
}

const videos = (state = initialState , action) => {

    switch(action.type) {
        case 'FETCH_VIDEOS':
           //return state.posts =  action.payload
          return {...state,...{loading: true },...action.payload}
      
         case 'UPDATE_VIDEOS':
            //return {...state,...{loading: true },...action.payload}
            return {...state,...{loading: true },...action.payload}
            
        default:
            return state
    }
}

export default videos