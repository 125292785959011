import React from 'react'
import { Container } from 'react-bootstrap'
import './live.css'

const live = () => {

  return (

    <Container>
     <div class="py-5"></div>
    <div>
<div class="flowplayer-embed-container top" >
        <iframe webkitAllowFullScreen mozallowfullscreen allowfullscreen src="https://embed.wowza.com/api/video/embed.jsp?id=z4ppfdrh&pi=02daa5c5-dba4-42e4-af06-528ececb02da" width="1200px" height="700px" title="The Channel 7 Live (Channel 7)" byline="0" portrait="0" frameborder="0" ></iframe>
      </div>
    </div>
    <div class="py-5"></div>
    <div class="py-5"></div>
    <div class="py-5"></div>
    </Container>
  )
}

export default live
