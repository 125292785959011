import React, { useEffect, useState } from 'react'
import Mynav from '../../components/Header/Header'
import { Container, Col, Row } from 'react-bootstrap'

import axios from 'axios'
import SpinnerComponents from '../../components/Spinner/SpinnerComponents'
import PaginationComponent from '../../components/Pagination/Pagination'
import { useDispatch, useSelector } from 'react-redux'
import './show.css'
import { editPost, fetchShows } from '../../redux/actions/showsactions'
import { Link } from 'react-router-dom'
const Show = () => {
const [category, setCategory] = useState([])
const [loading , setLoading] = useState(false)
const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);


  const itemsPerPage = 12; // You can ch


  const shows = useSelector(state => state.shows)

  //const programinfo = useSelector(state => state.programs)

  console.log("shows", shows)

  const dispatch = useDispatch()
 let {shows:myshows, loading:isloading} = shows
  useEffect(() => {


    const myfetch =  async() => {

      const  res = await  axios.get('https://nastaanaisie.pythonanywhere.com/api/categories/')
      console.log("res", res)
  
      setCategory(res.data)
      setLoading(true)
  
    }

    myfetch()

    dispatch(fetchShows())

  }, [])




let totalItems = myshows.length;
  let totalPages = Math.ceil(totalItems / itemsPerPage);
  let indexOfLastItem = currentPage * itemsPerPage;
 let indexOfFirstItem = indexOfLastItem - itemsPerPage;
  let currentItems = myshows.slice(indexOfFirstItem, indexOfLastItem);

  console.log('cyy',currentItems)

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const catFilter = (name) => {

    console.log(name)
    let items = currentItems.filter((cat) => 
      
      cat.name === name
    
    
    )

    //myshows.push(items)
if (items.length > 0 ){


  dispatch(editPost(items))

} else{

  dispatch(fetchShows())
}

  
   
   
  




   //myshows = items
 



  }


 useSelector(state => state.shows)
 
  return (

   <Container>
    {/* <div className='show-cat'>
      {category && category.map(item => (

<p className='cat' onClick={(e) => catFilter(item.name)}>{item.name}</p>
      ))}
  
    </div> */}
     


        <Row className='mt-3'>
       

          
        {!isloading? <SpinnerComponents/> : currentItems.map(item => (

        
        <Col sm={3} lg={3} md={3} key={item.id}>
          <Link to={`/shows/${item.id}`} className='show-links'>

        <img src={item.Image}  className='show-images mb-0' />

        </Link>
       <h6 className='mt-2 py-2'>{item.title}</h6>
        
      
        
        </Col>

        

))}
     
        
      </Row>
     

      <PaginationComponent
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />

    </Container>
  )



}

export default Show