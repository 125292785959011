const initialState = {

   
    loading: false,
    programs: []
}

const programs = (state = initialState , action) => {

    switch(action.type) {
        case 'FETCH_PROGRAM':
           //return state.posts =  action.payload
          return {...state,...{loading: true },...action.payload}
      
    
            
        default:
            return state
    }
}

export default programs