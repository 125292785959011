import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
const Terms = () => {
  return (
    <Container>
    <Row>
    <Col>
    <div>


    <h2 className='text-center mt-2 mb-2'>Terms & Conditions</h2>
    <h5 className='badge text-primary'>Last Updated: 07-12-2023</h5>

<p className='text-justify'>
    

Welcome to MyChannel7 TV Station ("we," "our," "us"). These Terms and Conditions ("Terms") govern your use of our website, mobile application, and other services (collectively, "Services"). By accessing or using our Services, you agree to be bound by these Terms. If you do not agree with any part of these Terms, you should not use our Services.

1. Acceptance of Terms

By accessing or using the Services, you confirm that you have read, understood, and agree to be bound by these Terms. If you are using the Services on behalf of an organization, you represent and warrant that you have the authority to bind that organization to these Terms.

2. Changes to Terms

We reserve the right to modify or replace these Terms at any time. Any changes will be effective immediately upon posting the updated Terms on our website. Your continued use of the Services after any such changes constitutes your acceptance of the new Terms. We encourage you to review these Terms periodically.

3. Eligibility

You must be at least 18 years of age to use our Services. By using the Services, you represent and warrant that you meet this eligibility requirement. If you are under 18 years old, you may use the Services only with the involvement of a parent or guardian.

4. User Accounts

To access certain features of our Services, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information as necessary. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.

5. Use of the Services

a. License to Use the Services
We grant you a limited, non-exclusive, non-transferable, revocable license to access and use the Services for your personal, non-commercial use, subject to your compliance with these Terms.

b. Prohibited Conduct
You agree not to engage in any of the following prohibited activities:

Violating any applicable laws or regulations.
Infringing on the rights of others, including intellectual property rights.
Uploading or transmitting any viruses, malware, or other harmful code.
Using the Services for any unauthorized or illegal purposes.
Attempting to interfere with or disrupt the operation of the Services.
6. Content

a. User-Generated Content
You may be allowed to post, submit, or share content, including but not limited to comments, reviews, and other materials ("User Content"). By submitting User Content, you grant us a non-exclusive, worldwide, royalty-free, perpetual, irrevocable, and sublicensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such User Content in any media.

You represent and warrant that you own or have the necessary rights to submit the User Content and that the User Content does not violate any third-party rights or applicable laws.

b. Intellectual Property
All content provided by us, including but not limited to text, graphics, logos, images, audio clips, video clips, and software, is the property of MyChannel7 TV Station or its licensors and is protected by intellectual property laws. You may not use, reproduce, distribute, or create derivative works based on our content without our prior written consent.

7. Third-Party Links

Our Services may contain links to third-party websites or services that are not owned or controlled by us. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You acknowledge and agree that we shall not be responsible or liable, directly or indirectly, for any damage or loss caused by or in connection with your use of any third-party websites or services.

8. Disclaimers and Limitation of Liability

a. Disclaimers
The Services are provided on an "as-is" and "as-available" basis. We make no warranties, express or implied, regarding the operation or availability of the Services, or the information, content, materials, or products included on the Services. We disclaim all warranties, express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, and non-infringement.

b. Limitation of Liability
To the maximum extent permitted by applicable law, in no event shall MyChannel7 TV Station, its affiliates, directors, employees, or agents be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from your use or inability to use the Services.

9. Indemnification

You agree to indemnify, defend, and hold harmless MyChannel7 TV Station and its affiliates, directors, employees, and agents from and against any and all claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees, arising out of or in any way connected with your access to or use of the Services, your violation of these Terms, or your infringement of any third-party rights.

10. Termination

We reserve the right to terminate or suspend your account and access to the Services at our sole discretion, without notice and for any reason, including, but not limited to, a breach of these Terms.

11. Governing Law

These Terms shall be governed by and construed in accordance with the laws of [Your Country/State], without regard to its conflict of law provisions. You agree to submit to the personal and exclusive jurisdiction of the courts located within [Your Jurisdiction] for the resolution of any disputes arising out of or relating to these Terms or your use of the Services.

12. Severability

If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions will remain in full force and effect.

13. Entire Agreement

These Terms constitute the entire agreement between you and MyChannel7 TV Station regarding your use of the Services and supersede any prior agreements between you and us.

14. Contact Us

If you have any questions or concerns about these Terms, please contact us at:

<h6 className='badge text-warning'>
MyChannel7 TV Station
info@mychannel7tv.co.uk
    </h6>
 </p>


    </div>
    </Col>
    </Row>

   
     

</Container>

)
}

export default Terms