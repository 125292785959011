import { applyMiddleware, combineReducers } from "@reduxjs/toolkit";
import { configureStore} from "@reduxjs/toolkit";
import News from "./reducers/News";
import posts from "./reducers/reducer";
import shows from "./reducers/showsReducers";
import programs from "./reducers/programReducers";
import videos from "./reducers/videosReducers";
import news from "./reducers/newsReducers";
import movies from "./reducers/moviesReducers";
// import { thunk } from "redux-thunk";
const rootReducer = combineReducers({


    news:news,
    posts:posts,
    shows:shows,
    programs:programs,
    videos: videos,
    movies:movies

})

const initalState = {

}

// const middleware = [thunk]

const store = configureStore({


    reducer: rootReducer
  
  
})



export default store