import Carousel from 'react-bootstrap/Carousel';
 import slide1 from '../../assets/img/slides/slide1.png'
 import slide2 from '../../assets/img/slides/slide2.png'
 import slide3 from '../../assets/img/slides/SlideHome.png'
import './slider.css'
import slide4 from '../../assets/img/slides/slide4.png'
import { Container } from 'react-bootstrap';
function Slider(props) {

 
  return (

<Container fluid>
<div class="row-fluid">
    <Carousel data-bs-theme="dark">
    <Carousel.Item>
      <img
        className="d-block img-fluid"
        src={slide3}
        alt="First slide"
      />
      <Carousel.Caption>
        {/* <h5>First slide label</h5>
        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
      <img
        className="d-block img-fluid"
        src={slide4}
        alt="Second slide"
      />
      <Carousel.Caption>
        {/* <h5>Second slide label</h5>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
      {/* <img
        className="d-block w-100"
        src={slide1}
        alt="Third slide"
      /> */}

<div className="flowplayer-embed-container top"  >
        <iframe webkitAllowFullScreen mozallowfullscreen allowfullscreen src="https://embed.wowza.com/api/video/embed.jsp?id=14c7799a-43fc-4118-9cea-1ea1e3e887c4&pi=02daa5c5-dba4-42e4-af06-528ececb02da" title="Test 2 on May 14, 2024 @ 07:59PM GMT" byline="0" portrait="0" frameborder="0" allow="autoplay"  className='bottom'></iframe>
      </div>
      <Carousel.Caption>
        {/* <h5 className='text-white'>Third slide label</h5>
        <p>
          Praesent commodo cursus magna, vel scelerisque nisl consectetur.
        </p> */}
      </Carousel.Caption>
    </Carousel.Item>
  </Carousel>
  </div>
  </Container>
  );
}

export default Slider;