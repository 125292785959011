const initialState = {

   
    loading: false,
    movies: []
}

const movies = (state = initialState , action) => {

    switch(action.type) {
        case 'FETCH_MOVIES':
           //return state.posts =  action.payload
          return {...state,...{loading: true },...action.payload}
      
         case 'UPDATE_MOVIES':
            //return {...state,...{loading: true },...action.payload}
            return {...state,...{loading: true },...action.payload}
            
        default:
            return state
    }
}

export default movies