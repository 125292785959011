
import axios from 'axios'
import { useEffect } from 'react'

//synchronous action creator


const fetchNewsData= (news) => ({


    type: 'FETCH_NEWS',
    payload: {news}
})


const updateNewsData = (news) => ({

    type: 'UPDATE_NEWS',
    payload: {news}
})

export const editPost = (news) => (dispatch) => {
    dispatch(updateNewsData(news));
  };

export const fetchNews =  () => {
    return async dispatch => {
        try {
            //let posts = await axios.get('https://mychannel7tv.co.uk/wp-json/wp/v2/show')
            let posts  = await axios.get('https://nastaanaisie.pythonanywhere.com/api/news/')
            dispatch(fetchNewsData(posts.data)) //store first five posts
           // dispatch(loading(true))

          
        }
        catch(e){
            console.log(e)
        }
    }
}

