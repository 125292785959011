import Carousel from 'react-bootstrap/Carousel';
 import slide1 from '../../assets/img/slides/slide1.png'
 import slide2 from '../../assets/img/slides/slide2.png'
import './slider.css'
import { Container } from 'react-bootstrap';

import { useEffect, useState } from 'react';

import axios from 'axios'
function NewsSlide() {

    const [data, setData] = useState([])

    useEffect(() => {

        const myfetch =  async() => {

            const res = await axios.get('https://nastaanaisie.pythonanywhere.com/api/news/')
        
          setData(res.data)
          
      
           }
        
            myfetch()
    
      }, [])
 
  return (



    

<Container className='mt-3 mb-4'>
<div class="row-fluid">
    <Carousel data-bs-theme="dark">

        {data && data.map(item => (
    <Carousel.Item key={item.id}>
      <img
        className="d-block img-fluid"
        src={item.Image}
        alt="First slide"
      />
      <Carousel.Caption>
        <h5 className='text-white'>{item.title}</h5>
        <p className='text-truncate text-white'>{item.content}</p>
      </Carousel.Caption>
    </Carousel.Item>

))}


  </Carousel>
  </div>
  </Container>
  );
}

export default NewsSlide;