import logo from './logo.svg';
import './App.css';
import mynav from './components/Header/Header';
import Mynav from './components/Header/Header';
import { MemoryRouter, Routes, Route, Link } from 'react-router-dom';
import Container from 'react-bootstrap/esm/Container';

import { Button } from 'react-bootstrap';
import About from './screen/About/About';
import Home from './screen/Home/Home';
import Show from './screen/Show/Show';
import Contact from './screen/Contact/Contact';
import Schedule from './screen/Schedule/Schedule';
import Slider from './components/Slider/Slider';
import {Swiper, SwiperSlide} from 'swiper/react'

import moment from 'moment'

import 'swiper/css/pagination';
import 'swiper/css';
import { EffectFade, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css/effect-fade';
import axios from 'axios'
import { useEffect, useState, useRef } from 'react';
import { Thumbs } from 'swiper/modules';
import 'swiper/css/navigation';
import SpinnerComponents from './components/Spinner/SpinnerComponents';
import { useDispatch, useSelector } from 'react-redux';
import { fetchShows } from './redux/actions/showsactions';
import { fetchVideos } from './redux/actions/videossactions';
import Feature from './components/Feature/Feature';


function App() {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const dispatch = useDispatch()

  const[show , setShow] = useState([])

  const [getRandom, setGetrandom] = useState([]);

  const [movies, setMovies] = useState([])

  const[post, setPost] = useState([])

  const [loading, setLoading] =useState(false)

  const swiperRef = useRef(null);

  const format2 = "YYYY-MM-DD"


  const shows = useSelector(state => state.videos)
  useEffect(  () => {
  
const  getAllMovies = async() => {


  //const res = await axios.get('https://mychannel7tv.co.uk/wp-json/wp/v2/movie ')
  const  res = await axios.get('https://nastaanaisie.pythonanywhere.com/api/movies/')

  console.log("movies",res.data)
  setMovies(res.data)




}


const getallPost = async() => {


  const res = await axios.get('https://nastaanaisie.pythonanywhere.com/api/news/ ')

  console.log("post",res.data)
  setPost(res.data)

  setLoading(true)
}


getallPost()





  
getAllMovies()


    const getRandomShows = async() => {

      // const res = await axios.get('https://mychannel7tv.co.uk/wp-json/wp/v2/show?modified_after=2024-03-16T00:00:00Z&order=asc ')
const res = await axios.get('https://nastaanaisie.pythonanywhere.com/api/videos/')
      console.log("reset",res.data)
      setGetrandom(res.data)

      setLoading(true)

    }

    // Object.assign(swiperContainer, params);
    // swiperContainer.initialize()
    const myfetch =  async() => {

      //const  res = await  axios.get('https://mychannel7tv.co.uk/wp-json/wp/v2/show')
      const res = await axios.get('https://nastaanaisie.pythonanywhere.com/api/videos')
      console.log("res", res)
  
      setShow(res.data)
      setLoading(true)
  
    }

    //myfetch()

    getRandomShows()

    //dispatch(fetchShows())

    dispatch(fetchVideos())

    }, [])

   
 
    const news = useSelector(state => state.shows)
    // dispatch(fetchShows())
    
     const showsre = useSelector(state => state.shows)
      
      const videos = useSelector(state => state.videos)
    
      // const {shows, loading:isloading} = news
console.log('videos', videos)

      let {videos:videi, loading:isloading} = videos
    
      console.log("reducer",showsre)
  

  return (

<>
<Slider fluid/>


    <Container className="p-3">
    <div className="App" >




{/* <h6 className="heading h6 mt-3 ml-3 mb-4 mt-2 mx-auto text-center"> New Episodes</h6> */}

{/* <h4 className="heading h4 mx-auto  mb-0 mt-3"> Recently Added</h4> */}

<div className='mt-3 mb-5 services-heading mb-5 '>
<h6 className="heading h6 mx-auto  mb-0 mt-5">Recently Added</h6>
<h3 className="heading h3 mt-3 ml-3 mb-4 mt-2 mx-auto  mb-5 text-wrap">LATEST EPISODES AND MORE</h3> 
        </div>

<Swiper

style={{marginTop: 24}}
      spaceBetween={50}
      slidesPerView={3}
      onSlideChange={() => console.log('slide change')}
      // onSwiper={(swiper) => console.log(swiper)}
      navigation={true}
      // modules={[EffectFade]}
      className="mySwiper"
      modules={[Navigation, Pagination]}
      watchSlidesProgress
      onSwiper={setThumbsSwiper}
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      
      
      breakpoints={{

     
        0: {
          slidesPerView: 1,
        },
        400:{
          slidesPerView:2,
        },
        639: {
          slidesPerView: 2,
        },
        865:{
          slidesPerView:3
        },
        1000:{
          slidesPerView:4
        },
        1500:{
          slidesPerView:4
        },
        1700:{
          slidesPerView:4
        }
    }}
      
      
      
      
      >
    

    { !isloading? <SpinnerComponents/>:videi.map(item => (
   
      <SwiperSlide key={item.id} className='mb-3'>
     
        <iframe src={item.video_url}></iframe>
        <span>{item.episode.show.title}</span>
       <h6 className='text-truncate py-2'>{item.title}</h6> 
        
  
        </SwiperSlide>

    ))}
  
     
    </Swiper>






 



{/* <h6 className="heading h6 mt-3 ml-3 mb-4 mt-2 mx-auto text-center">AFRICAN MOVIES</h6>

<h1 className="heading h1 mt-3 ml-3 mb-4 mt-2 mx-auto text-center mb-5">FEATURED MOVIES</h1> */}
{/* <h4 className="heading h4 mx-auto  mb-0 mt-4">Featured Movies</h4> */}



<div className='mt-5 mb-5 services-heading mb-5 '>
        {/* <h2 className='h2 mx-auto our-heading'>Featured Movies </h2>
        <p className=' mx-auto  our-desc'>Latest African movies </p> */}

        <h6 className="heading h6 mx-auto  mb-0 mt-5">Channel Movies</h6>
<h3 className="heading h3 mt-3 ml-3 mb-4 mt-2 mx-auto  mb-5 text-wrap">LATEST AFRICAN MOVIES & SHOWS</h3> 
        </div>
<Swiper

style={{marginTop: 24}}
      spaceBetween={50}
      slidesPerView={5}
      onSlideChange={() => console.log('slide change')}
      // onSwiper={(swiper) => console.log(swiper)}
      navigation={true}
      // modules={[EffectFade]}
      className="mySwiper"
      modules={[Navigation, Pagination]}
      watchSlidesProgress
      onSwiper={setThumbsSwiper}
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      
      
      
      
      breakpoints={{

     
        0: {
          slidesPerView: 1,
        },
        400:{
          slidesPerView:2,
        },
        639: {
          slidesPerView: 3,
        },
        865:{
          slidesPerView:3
        },
        1000:{
          slidesPerView:4
        },
        1500:{
          slidesPerView:5
        },
        1700:{
          slidesPerView:5
        }
    }}
      
      
      
      
      
      
      
      
      
      
      
      >
    

    {movies && movies.map(item => (
   
      <SwiperSlide key={item.id}>
     
        {/* <iframe src={item['toolset-meta']['images']['images-field']['raw']}></iframe> */}

        <img src={item.Image}  className='feat-imge'/>

       <h6 className='py-2'>{item.title}</h6> 
        
 



        </SwiperSlide>

    ))}
  
     
    </Swiper>













 


{/* <h6 className="heading h6 mt-3 ml-3 mb-4 mt-2 mx-auto text-center">CHANNEL PICK</h6>

<h1 className="heading h1 mt-3 ml-3 mb-4 mt-2 mx-auto text-center mb-5">LATEST EPISODES</h1> */}
{/* <h4 className="heading h4 mx-auto  mb-0 mt-4">Latest Episodes</h4> */}

{/* <div className='mt-5 mb-5 services-heading mb-2 '>
        <h2 className='h2 mx-auto our-heading'>Latest Episodes</h2>
        <p className=' mx-auto h6 our-desc'>Catch up on all latest Episodes and  </p>
        </div>
<Swiper

style={{marginTop: 24}}
      spaceBetween={50}
      slidesPerView={3}
      onSlideChange={() => console.log('slide change')}
      // onSwiper={(swiper) => console.log(swiper)}
      navigation={true}
      // modules={[EffectFade]}
      className="mySwiper"
      modules={[Navigation, Pagination]}
      watchSlidesProgress
      onSwiper={setThumbsSwiper}
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      
      
      breakpoints={{

     
        0: {
          slidesPerView: 1,
        },
        400:{
          slidesPerView:2,
        },
        639: {
          slidesPerView: 3,
        },
        865:{
          slidesPerView:3
        },
        1000:{
          slidesPerView:4
        },
        1500:{
          slidesPerView:5
        },
        1700:{
          slidesPerView:5
        }
    }}
      
      
      
      
      >
    

    {!loading? <SpinnerComponents/> :getRandom.map(item => (
   
      <SwiperSlide key={item.id}>
     
        
        <iframe src={item.video_url}></iframe>
       <h6>{item.title}</h6> 
        
      


        </SwiperSlide>

    ))}
  
     
    </Swiper> */}

 














<Container className='Container live fluid mt-5 mb-5' fluid>
<div className="live-text text-center">

<h2 className='mt-3 text-center'>Watch Live  TV</h2>
  <p className='text-center'>Stream Live Television, Enjoy Channel Shows At the Comfort of Your Home </p>

  <Button variant='danger' className='app-btn text-white'> <Link to="https://mychannel7tv.co.uk/live" className='text-white'>Watch Live</Link></Button>

</div>

</Container>






   
{/* 
    <h6 className="heading h6 mt-3 ml-3 mb-4 mt-2 mx-auto text-center">LATEST STORIES</h6>

<h1 className="heading h1 mt-3 ml-3 mb-4 mt-2 mx-auto text-center mb-5">CHANNEL NEWS</h1> */}
<h6 className="heading h6 mx-auto mb-0 mt-5">What's News</h6>
<h3 className="heading h3  ml-3 mb-4 mt-2 mx-auto  mb-1 text-wrap">LATEST CHANNEL NEWS AND UPDATES</h3> 
<Swiper

style={{marginTop: 24}}
      spaceBetween={50}
      slidesPerView={3}
      onSlideChange={() => console.log('slide change')}
      // onSwiper={(swiper) => console.log(swiper)}
      // navigation={true}
      // modules={[EffectFade]}
      className="mySwiper"
      modules={[Navigation, Pagination]}
     
      watchSlidesProgress
      onSwiper={setThumbsSwiper}
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      
      
      
      breakpoints={{

     
        0: {
          slidesPerView: 1,
        },
        400:{
          slidesPerView:2,
        },
        639: {
          slidesPerView: 3,
        },
        865:{
          slidesPerView:3
        },
        1000:{
          slidesPerView:4
        },
        1500:{
          slidesPerView:3
        },
        1700:{
          slidesPerView:3
        }
    }}
      
      
      
      
      
      
      >
    

    {!loading? <SpinnerComponents/> : post.map(item => (
   
      <SwiperSlide key={item.id}>
     
        {/* <iframe src={item['toolset-meta']['images']['images-field']['raw']}></iframe> */}

        <img src={item.Image}  className='featuredImage' />
        <p className='h5 mt-2 news-title badge text-bg-primary'>{item.category.name}</p>
       <h4 className='news-info text-truncate h5'>{item.title}</h4>
       <span>{moment(item.publish_date).format(format2)}</span> || Author: <span>{item.Author}</span>
       <div className="mb-4 h6"> <Link to={`/news/${item.id}`}><h6 className='news-info text-truncate h6'>Learn More</h6></Link></div>
      

        
      



        </SwiperSlide>

    ))}
  
     
    </Swiper>

 






















    </div>



    </Container>

    </>
  );
}

export default App;
