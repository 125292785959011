// TVSchedule.js

import { Table } from 'react-bootstrap';
import axios from 'axios';

import {  Col, Row } from 'react-bootstrap'
import PaginationComponent from '../../components/Pagination/Pagination';
import { MemoryRouter, Routes, Route, Link } from 'react-router-dom';
import Container from 'react-bootstrap/esm/Container';



import {Swiper, SwiperSlide} from 'swiper/react'



import 'swiper/css/pagination';
import 'swiper/css';
import { EffectFade, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css/effect-fade';

import { useEffect, useState, useRef } from 'react';
import { Thumbs } from 'swiper/modules';
import 'swiper/css/navigation';
import SpinnerComponents from '../../components/Spinner/SpinnerComponents';
import { useDispatch, useSelector } from 'react-redux';

const TVSchedule = () => {
    const [schedule, setSchedule] = useState([]);
    const programinfo = useSelector(state => state.programs)

    const {loading:isloading, programs} = programinfo
 
  
    const handlePageChange = (page) => {
      setCurrentPage(page);
    };
    let output;

    const getShowDay  = (score) => {
        switch(score){

            case  0:
                output = "Sunday"
                break;
                case 1:
                    output = "Monday"
                    break;
                    case  2:
                        output =  "Tuesday"
                        break;
                        case 3:
                            output =  "Wednesday"
                            break;
                            case 4:
                                output =   "Thursday"
                                break;
                                case  5:
                                    output = "Friday"
                                    break;
            default:
                output = "hursday"
        }



    }

    useEffect(() => {
        // Fetch schedule from the Django API
        const fetchSchedule = async () => {
            try {
                const response = await axios.get('https://nastaanaisie.pythonanywhere.com/api/current-schedule/');
                setSchedule(response.data);
            } catch (error) {
                console.error('Error fetching the schedule:', error);
            }
        };

        fetchSchedule();
    }, []);


    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 12; // You can ch
    const totalItems = schedule.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = schedule.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <div className="container mt-4">
            <h2 className='text-center'>Current TV Schedule</h2>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Show Title</th>
                        <th>Show Date(Day)</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((item) => (
                        <tr key={item.id}>
                            <>{getShowDay(new Date(item.start_time).getDay())}</>
                           
                            <td>{item.show.title}</td>
                            <td>  {output}</td>
            
                            <td>{new Date(item.start_time).toTimeString('en-GB', { timeZone: 'Europe/London' })}</td>
                            <td>{new Date(item.end_time).toTimeString('en-GB', { timeZone: 'Europe/London' })}</td>
                        </tr>
                    ))}


    
                </tbody>


            </Table>

            <Row>

          
{ !isloading? <SpinnerComponents/>  :  currentItems.map(item => (

  
<Col lg={4}   md={6} className="mr-5 ml-5" key={item.id}>


<h6>{item.title}</h6> 



</Col>

))}



<PaginationComponent
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />

</Row>





        </div>
    );
};

export default TVSchedule;