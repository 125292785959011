const initialState = {

    posts: [],
    products: [],
    loading: false,
    shows: []
}

const posts = (state = initialState , action) => {

    switch(action.type) {
        case 'FETCH_POSTS_SUCCESS':
           //return state.posts =  action.payload
          return {...state, ...{loading: true }, ...action.payload}
      
    
            
        default:
            return state
    }
}

export default posts